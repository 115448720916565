@import './reset.scss';
@import './swiper.scss';
@import './scroll.scss';

// html, body { scroll-behavior:smooth; }

body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
  color: #495060;
}

img {
  vertical-align: middle;
  // width: 100%;
}

label {
  font-weight: 700;
}

html {
  height: 100%;
  box-sizing: border-box;
}

#app {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  outline: none;
  text-decoration: none;
}

div:focus {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

.g-width {
  width: 1200px;
  margin: 0 auto;
  padding-left: 1%;
  padding-right: 1%;
}

.g-min-width {
  min-width: 1200px;
}

.g-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  >div {
    flex: 1;
  }
}

header {
  box-shadow: 0 0 9px -6px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: #fff;
  li {
    .active-header {
      color: #FE6746;
      &::before {
        transform: translate(-50%, 0) scaleX(1);
      }
    }
  }
}
