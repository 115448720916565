@charset "UTF-8";
/* 清除内外边距 */
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote,
dl, dt, dd, ul, ol, li,
pre,
fieldset, lengend, button, input, textarea,
th, td { /* table elements 表格元素 */
  margin: 0;
  padding: 0;
}

/* 设置默认字体 */
body,
button, input, select, textarea { /* for ie */
  /*font: 12px/1 Tahoma, Helvetica, Arial, "宋体", sans-serif;*/
  font: 12px/1 Tahoma, Helvetica, Arial, "宋体", sans-serif; /* 用 ascii 字符表示，使得在任何编码下都无问题 */
}

h1 {
  font-size: 18px; /* 18px / 12px = 1.5 */
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

h4, h5, h6 {
  font-size: 100%;
}

address, cite, dfn, em, var {
  font-style: normal;
} /* 将斜体扶正 */
code, kbd, pre, samp, tt {
  font-family: "Courier New", Courier, monospace;
} /* 统一等宽字体 */
small {
  font-size: 12px;
} /* 小于 12px 的中文很难阅读，让 small 正常化 */
/* 重置列表元素 */
ul, ol {
  list-style: none;
}

/* 重置文本格式元素 */
a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

abbr[title], acronym[title] { /* 注：1.ie6 不支持 abbr; 2.这里用了属性选择符，ie6 下无效果 */
  border-bottom: 1px dotted;
  cursor: help;
}

q:before, q:after {
  content: "";
}

/* 重置表单元素 */
legend {
  color: #000;
} /* for ie6 */
fieldset, img {
  border: none;
  vertical-align: middle;
} /* img 搭车：让链接里的 img 无边框 */
/* 注：optgroup 无法扶正 */
button, input, select, textarea {
  font-size: 100%; /* 使得表单元素在 ie 下能继承字体大小 */
}

/* 重置表格元素 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 重置 hr */
hr {
  border: none;
  height: 1px;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  width: 100%;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 4em;
  height: 3px;
  border-radius: 0;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgb(241, 241, 241);
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1em;
  background-color: rgb(241, 241, 241);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 1em;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(193, 193, 193);
}

body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
  color: #495060;
}

img {
  vertical-align: middle;
}

label {
  font-weight: 700;
}

html {
  height: 100%;
  box-sizing: border-box;
}

#app {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  outline: none;
  text-decoration: none;
}

div:focus {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.g-width {
  width: 1200px;
  margin: 0 auto;
  padding-left: 1%;
  padding-right: 1%;
}

.g-min-width {
  min-width: 1200px;
}

.g-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.g-flex > div {
  flex: 1;
}

header {
  box-shadow: 0 0 9px -6px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: #fff;
}
header li .active-header {
  color: #FE6746;
}
header li .active-header::before {
  transform: translate(-50%, 0) scaleX(1);
}