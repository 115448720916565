.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 4em;
  height: 3px;
  border-radius: 0;
}