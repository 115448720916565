/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
//自动移滚动条样式
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgb(241,241,241);
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1em;
  background-color: rgb(241,241,241);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 1em;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgb(193,193,193);
}